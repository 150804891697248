//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from '../dependencies/index'

export default {
  name: 'BienAlertEmail',
  components: {
    PictoAlert: () => import('./PictoAlert'),
    PictoArrow: () => import('./PictoArrow')
  },
  props: {
    bien: Object
  },
  data: function () {
    return {
      email: '',
      error: null,
      formSent: null,
      request: null
    }
  },
  methods: {
    showAlertMailModal () {
      console.log(this.bien)
      this.request = {
        city: this.bien.cityNames,
        type: this.bien.type,
        nbrPieces: this.bien.nbrPieces,
        surface: (this.bien.surface.toString().split(',').join('-') + ' m²'),
        rayon: this.bien.rayon + ' km',
        budget: (this.bien.budget.toString().split(',').join('-') + ' €')
      }
      this.$bvModal.show('alertMailModal')
    },
    async sendEmailToAlert () {
      if (document.getElementById('emailInput').reportValidity()) {
        const options = {
          apiUrl: this.$apiUrl,
          databaseName: this.$databaseName,
          token: await this.$token
        }
        const request = 'type: ' + this.bien.type +
          ' nbrPieces: ' + this.bien.nbrPieces +
          ' surface: ' + this.bien.surface +
          ' rayon: ' + this.bien.rayon +
          ' budget: ' + this.bien.budget
        const demandeToSend = {
          email: this.email,
          telephone: '',
          adresse: '',
          codePostal: '',
          ville: '',
          pays: '',
          questions: request,
          rgpd: '',
          type: 'alerte',
          destinataire: 'ti'
        }
        const dataApi = new DataApi(options)
        dataApi.createRecord('W_DemandeContact', demandeToSend, 
          [{
            name: 'validerFormulaire',
            type: 'postrequest'
          }])
          .then((response) => {
            this.formSent = true
            setTimeout(() => {
              this.formSent = false
              this.$bvModal.hide('alertMailModal')
            }, 3000)
          })
          .catch((error) => {
            console.log(error)
            this.error = 'La demande n\'a pas pu être enregistrée, veuillez reéssayer plus tard'
            setTimeout(() => {
              this.error = null
              this.$bvModal.hide('alertMailModal')
            }, 3000)
          })
      }
      this.email = null
    }
  }
}
